<script>
import DetailFormGroup from '../../../components/DetailFormGroup'
import api from '@/command/api'
import { Table, Message } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '../../../command/netTool'

export default {
  name: 'ruralVideoDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {},
      idData: null,
    }
  },
  mounted() {
    this.idData = this.$route.query.id
    if (this.idData) {
      api.command.getDetail.call(this, {
        url: `/farmCountryVideo/detail?id=${this.idData}`,
      })
    }
  },
  methods: {
    getForm1() {
      return {
        title: '上传视频',
        type: 'cardForm',
        data: [
            {
            name: '名称',
            type: 'input',
            key: 'name',
            cols: 12,
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            type: 'iconUploadVideo',
            cols: 24,
            typeData: [
              {
                maxLength: 3,
                key: 'videoUrl',
              },
            ],
          },
        ],
      }
    },
    getFormBanner() {
      let that = this
      return {
        title: () => (
          <div>
            <span style="color:#f00">*</span>
            预览图 限制1张
          </div>
        ),
        type: 'cardForm',
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            key: 'a12',
            typeData: [
              {
                maxLength: 1,
                key: 'bannerUrl',
                desc: '@2x   xhdpi <br/> 上传尺寸750*480px',
              },
            ],
            rules: [
              {
                // eslint-disable-next-line max-params
                validator(rule, value, callback, source, options) {
                  if (!that.detail.bannerUrl) {
                    return false
                  } else {
                    return true
                  }
                },
                message: '请选择图片上传',
              },
            ],
          },
          {
            name: '排序序号（数字越小排序越前，不填默认按创建时间倒序',
            type: 'input',
            cols: 12,
            key: 'sort',
          },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: () => {
            ;(this.idData ? api.command.edit : api.command.editPost)
              .call(this, {
                url: `/farmCountryVideo/${this.idData ? 'update' : 'save'}`,
                params: {
                  ...this.detail,
                },
                isPost: false,
              })
              .then(() => {
                this.$router.back()
              })
          },
        },
        {
          name: '取消',
          onClick: () => {
            this.$router.push('/contentManagement/ruralVideo')
          },
        },
      ]

      const right = [
        {
          name: '删除',
          type: 'danger',
          popTitle: '确认是否删除吗?',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: '/farmCountryVideo/deleteBatch',
              params: { idList: [this.idData] },
              isPost: false,
            })
            setTimeout(() => {
              this.$router.push('/marketingCenter/rural')
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        data={[this.getForm1(), this.getFormBanner()]}
        havedrag={true}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
/deep/ .ant-table-content {
  padding: 0 20px;
}
.TableTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.description_box {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 25px;
  max-height: 25px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
